@import url(../style/common.scss) only screen;
@import url(./media/footer-xs.scss) only screen and (max-width:375px);
@import url(./media/footer-small.scss) only screen and (max-width:768px);
@import url(./media/footer-media.scss) only screen and (min-width:768px) and (max-width: 1024px);
@import url(./media/footer-big.scss) only screen and (min-width:1024px);


.footer_row {
    padding-bottom: 40px;
    padding-top: 80px;
    background-color: #ffd602;
    color: #142b6f;
    display: block;
}

.footer_row_inner {
    padding: 0 40px;
    margin: 0 auto;
    max-width: 1300px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .footer_row_one {
        padding-left: 30px;


        .footer__logo {
            margin-bottom: 26.66667px;


            a {
                height: 36px;
                text-decoration: none;
            }
        }



        .footer__social {
            margin: 0;
            list-style: none outside;
            padding: 0;
            text-rendering: optimizeLegibility;

            li {
                margin: 0 15px 15px 0;
                display: inline-block;
            }

            a {
                display: block;
                color: currentColor;
                text-decoration: none;
            }

            .icon {
                height: 24px;
                width: 24px;
                fill: rgb(20, 43, 111);
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}



.footer__title {
    letter-spacing: .2em;
    text-transform: uppercase;
    color: #142b6f;
    font-weight: 700;
    line-height: 1.2;
    width: 100%;
    text-align: left;
}

.collapsible-trigger {
    color: inherit;
    position: relative;
    color: #142b6f;
    font-weight: 700;
    line-height: 1.2;
}

.collapsible-trigger__icon {
    display: block;
    height: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;

    .icon {
        display: block;
        height: 10px;
        transition: all .2s ease-in;
        width: 10px;
        fill: rgb(20, 43, 111);
    }

    .icon.rotated {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
    }
}

.collapsible-content__inner {
    transform: translateY(0);
    transition: transform .5s cubic-bezier(.25, .46, .45, .94);
}



@media screen and (width:768px) {
    .collapsible-content__inner {
        visibility: visible !important;
    }

    .footer_row_inner .footer_row_one .footer__logo {
        margin-bottom: 26.66667px !important;
    }

    .footer_row_two {
        border-bottom: none;
    }

    .collapsible-content__inner12 {
        opacity: 1;
        overflow: unset;
        transition: opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        visibility: visible;
        height: auto;
        display: block;
    }
}

.site-footer__linklist {
    margin: 0;
    list-style: none outside;
    padding: 0;
    text-rendering: optimizeLegibility;

    li {
        margin-bottom: .25em;

        a {
            color: rgb(20, 43, 111);
            display: block;
            padding: 4px 0;
            text-decoration: none;
            font-size: calc((17px)* .93);
        }
    }
}

.site-footer__bottom {
    text-align: center;

    .multi-selectors {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .site-footer__bottom-block {
        margin: 0 20px 20px;
    }

    .disclosure {
        position: relative;
    }

    button {
        padding: 7px 36px 7px 10px;
        background-color: #ffd602;
        border: 1px solid;
        border-color: rgb(20 43 111 / 12%);
        color: #142b6f;
        text-align: center;
    }

    .currency-flag.currency-flag--gb {
        background-position: 0 -4600px;
    }

    .currency-flag {
        background-image: url(https://wellgard.co.uk/cdn/shop/t/33/assets/country-flags-40.png);
        background-repeat: no-repeat;
        background-position: top left;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        overflow: hidden;
        box-shadow: 0 0 1px #0000004d inset;
        border-radius: 50%;
        background-clip: padding-box;
        margin: -.55rem;
        transform: scale(.55);
    }

    .currency-options__label--inline {
        display: inline-block;
        padding-left: 10px;
        width: auto;
    }

    .currency-options__label {
        text-underline-offset: .5rem;
        vertical-align: middle;
    }

    .site-footer__bottom-block.payment-icons {
        margin-bottom: 10px;
        padding-bottom: 0;
    }

    .payment-icons--greyscale {
        filter: grayscale(1);
    }

    .payment-icons {
        cursor: default;
    }

    .site-footer__bottom-block {
        margin: 0 20px 20px;
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            cursor: default;
            margin: 0 4px;
            padding-top: 4px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .footer__copyright {
        line-height: 1.6;
    }
}

@media only screen and (max-width: 590px) {
    .site-footer__bottom {
        padding-top: 10px;

        .site-footer__bottom-block {
            margin: 0 auto;
            padding-bottom: 15px;
            text-align: center;
        }
    }

    .footer__copyright {
        font-size: 12px;
    }
}