.index-section {
    margin: 100px 0;

    .page-full,
    .page-width {
        padding: 0 40px;
    }

    .page-width--narrow {

        .theme-block {
            h2 {
                font-size: calc((35px)* .9);
                margin: 0 0 20px;
            }
        }
    }
}

.section-header {
    margin-bottom: 50px;
}