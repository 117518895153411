.cart-page {
  position: relative;
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
  z-index: 10;
  padding: 0 20px;
  height: auto;
  margin-top: 90px;
  font-size: 14px;
  padding: 3% 0;
}

.cart-items {
  padding: 10px 15%;
  height: auto;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}

.cart-item svg {
  margin-right: 10px;
}

.cart-item img {
  width: 112px;
  height: 124px;
  object-fit: cover;
}

.cart-summary {
  display: flex;
  text-align: center;
  justify-content: end;
  padding: 10px 0px 0px;
}

.checkout-button {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  padding: 13px 32px;
  color: #142b6f;
  background: #ffd602;
  border-color: #e8e8e1;
  border-width: 0;
  border-radius: 5px;
  width: 180px;
  height: 50px;
  margin: 9% 0px 0 0;
}



.rightSum {
  width: 367px;
  text-align: left;
  padding-top: 1.8%;
}

.headeQty {
  width: 10%;
}

.css-1vwv5yk-MuiTableCell-root {
  border-right: none !important;
}

.MuiTableCell-root {
  border-right: none !important;
}

.css-7evlt1-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.cartInput {
  width: 100%;
  padding: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 39px;
}

.basket-title h1 {
  font-weight: 800;
  font-size: 3rem;
}

.basket-title {
  text-align: center;
}

.css-15wwp11-MuiTableHead-root {
  background-color: rgb(243, 243, 243) !important;
}

.css-1vwv5yk-MuiTableCell-root,
.css-1ex1afd-MuiTableCell-root,
.css-1yhpg23-MuiTableCell-root {
  border-bottom: none !important;
}

.css-apqrd9-MuiTableBody-root {
  border: 2px solid rgb(243, 243, 243);
  border-bottom-left-radius: 12px !important;
}

.MuiTableBody-root>.MuiTableRow-root:last-child {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.css-1ygcj2i-MuiTableCell-root,
.css-1ndpvdd-MuiTableCell-root {
  color: rgb(147, 147, 147) !important;
}

.rightSum table {
  width: 100%;
  border-spacing: 0px;
  background: none !important;
}

.rightSum tr {
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.rightSum td {
  font-weight: 600;
  font-size: 15px;
  padding-left: 0 !important;
}

.rightSum td:last-child {
  width: 35%;
  text-align: right;
  font-weight: 300;
  color: #b12704;
  font-weight: bold;
}

.rightSum td {
  width: 16%;
}

.rightSum .express {
  margin: 10px 0px;
  height: 72px;
  padding: 19px 20px;
}

.rightSum .total {
  margin: 10px 0px 0px;
  padding: 16px 16px 16px 5.5%;
  margin-top: 4%;
  background: #608daf;
  color: #000;
  height: 45px;
  padding-block: 13px;
}

.rightSum small {
  font-weight: 400;
}

.cart-grid {
  margin-top: 5%;
  padding: 0 0 0 22%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.td-left {
  width: 100% !important;
  text-align: left !important;
}

.btn___box {
  display: flex;
  justify-content: end;
}

.remove__btn {
  color: #b71029;
  border: 1px solid #b71029;
  background-color: #fff;
  width: 78px;
  height: 32px;
  border-radius: 0.375rem;
}

.egg_title {
  color: #142b6f;
  margin: 0;
  width: 100%;
  line-height: 1.2;
  font-size: 16px;
  font-weight: bold;
}

.rebuy-cart__flyout-item-quantity-widget {
  border-color: #e3e3e3;
  padding: 0;
  width: auto;
  display: inline-flex;
  flex-flow: row nowrap;
  place-content: stretch flex-start;
  align-items: stretch;
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  color: #142b6f;
  margin-top: 20px;

  button {
    color: #142b6f;
    border: none;
    background: #fff;
    display: block;
    margin: 0;
    outline: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    width: 36px;
    min-width: auto;
    height: 24px;
    min-height: auto;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    font-weight: bold;

    svg {
      vertical-align: middle;
      font-family: "Font Awesome 5 Pro";
      font-weight: bold;
      width: 10px;
      height: 12px;
    }
  }

  button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rebuy-cart__flyout-item-quantity-widget-label {
    border-color: #e3e3e3;
    border-width: 0 1px;
    border-style: solid;
    background: none;
    display: block;
    margin: 0;
    outline: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    width: 36px;
    min-width: auto;
    height: 24px;
    min-height: auto;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    font-weight: bold;
  }
}

.css-7evlt1-MuiPaper-root-MuiTableContainer-root {
  width: auto !important;
}

.egg_delete_item svg {
  width: 20px;
  height: 20px;
}

.css-rqglhn-MuiTable-root {
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
}

.css-1q1u3t4-MuiTableRow-root {
  display: grid !important;
  grid-template-columns: repeat(12, 1fr) !important;
}

.rebuy-cart__flyout-item-price {
  margin: 5px 0 0;
  padding: 0;
  text-align: left;
  line-height: 16px;
  font-size: 15px;
  margin-top: 14px;

  .rebuy-money.sale {
    color: #b12704;
  }
}