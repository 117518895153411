//min-width:768px and max-width: 1024px
.head_top {
    font-size: 16px;
}

.head_bot {
    .head_bot_inner {
        .head_bot_inner_mid {
            img {
                width: 180px;
                height: 44px;
            }
        }
    }
}