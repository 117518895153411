@import url(./common.scss) only screen;
@import url(./media/head-small.scss) only screen and (max-width:375px);
@import url(./media/head-small.scss) only screen and (max-width:768px);
@import url(./media/head-media.scss) only screen and (min-width:768px) and (max-width: 1024px);
@import url(./media/head-big.scss) only screen and (min-width:1024px);



.head_container {
    width: 100%;
}

.head_top {
    width: 100%;
    z-index: 6;
    background-color: #ffd602;
    color: #142b6f;
    height: 36.59px;
    max-height: 100px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0);
    font-weight: 400;
    transition: max-height .3s cubic-bezier(0, 0, .38, 1);

    span {
        display: block;
        text-align: center;
        transition: opacity .75s ease;
        font-size: 1rem;
        padding: 6px 20px 5px;
        line-height: 1.6;
    }
}

.head_bot {
    width: 100%;
    max-height: 112px;
    height: 112px;
    border-bottom: 1px solid;
    border-bottom-color: #e8e8e1;
    padding: 20px 0;
    background-color: #ffffff;
    position: relative;
    display: block;
    z-index: 28;
    display: flex;
    align-items: center;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .head_bot_inner {
        padding: 0 40px;
        margin: 0 auto;
        max-width: 1300px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .head_bot_inner_left {
            flex: 1 1 130px;
            align-items: center;
            display: flex;

            .site-nav svg {
                height: 28px;
                width: 28px;
                fill: rgb(20, 43, 111);
                vertical-align: middle;
            }
        }

        .head_bot_inner_mid {
            margin: 13.33333px 26.66667px;
            flex: 0 0 180px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;


        }

        .head_bot_inner_right {
            flex: 1 1 130px;
            justify-content: flex-end;
            align-items: center;
            display: flex;

            a {
                display: inline-block;
                padding: 10px 20px;
                text-decoration: none;
                vertical-align: middle;
                white-space: nowrap;
                color: #142b6f;
                position: relative;

                span svg {
                    height: 28px;
                    width: 28px;
                    overflow: hidden;
                    fill: currentColor;
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .cart-link__bubble--visible {
                background-color: #ff4f33;
                border: 2px solid;
                border-color: #fff;
                border-radius: 50%;
                display: block;
                height: 15px;
                position: absolute;
                right: 12px;
                top: 10px;
                width: 15px;
            }
        }
    }
}