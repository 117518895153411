.check-custom-checkout-page {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}


.check-custom-checkout-page .left {
  height: 100%;
}

.check-custom-checkout-page .right {
  border-left: 1px solid #eee;
  background: rgb(246, 245, 245)
}

.check-order-info,
.check-order-summary,
.check-cart-summary {
  padding: 20px;
}


.check-order-info h2 {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}



.check-order-info input {
  border: 1px solid hsl(0, 0%, 87%);
  height: 49px;
  border-radius: 5px;
  margin: 2% 0;
  padding: 10px;
  width: 100%;
}

.check-order-info textarea {
  border: 1px solid hsl(0, 0%, 87%);
  border-radius: 5px;
  height: 150px;
  margin: 2% 0;
  padding: 10px;
  width: 100%;
}

.check-input .form-control {
  height: 50px !important;
  width: 105% !important;
}

.check-input {
  margin: 2% 0;
}

.check-order-summary,
.check-cart-summary {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.check-order-summary h2,
.check-cart-summary h2 {
  margin-top: 0;
}

.check-order-details {
  margin-top: 10px;
}

.check_subtotal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 2%;
}

.check_subtotal span {
  font-size: 14px;
  font-weight: 500;
  color: rgb(37 37 37);
}

.check-cart-item {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 2%;
  position: relative;
  padding-left: 2%;
  padding-top: 2%;
}

.check-cart-item img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.check-item-details h3 {
  margin: 0;
  font-size: 1em;
  font-weight: 600;
}

.check-item-details p {
  margin: 0px 0;
  font-size: 0.9em;
}

.check-pre-price {
  text-decoration: line-through;
  margin-right: 5px !important;
  font-size: 0.9em !important;
}

.check-checkout-button {
  display: block;
  background: rgb(20, 43, 111);
  height: 56px;
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}

.check-checkout-button:hover {
  background: rgb(9, 24, 69);
  color: #fff;
}

.gridTotal {
  display: flex;
  width: 100%;
  margin-top: 3%;
}

.gridTotal span {
  width: 430px;
}

.gridTotal .grand {
  font-size: 18px;
  font-weight: 700;
}

.gridTotal .grand1 {
  font-size: 18px;
  font-weight: 700;
  text-align: end;
}

.check-select-container {
  width: 102%;
  height: 50px;
}

.endMsg {
  text-align: end;
}

.cartImg {
  display: flex;
}

.cartImg img {
  width: 40px;
  margin-right: 10px;
}

.cartDebit {
  display: flex;
}

.cartDebit p {
  margin-right: 70px;
  margin-top: 30px;
}

.payTr img {
  width: 60px;
}

.payTr th {
  display: flex;
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}

.payTr p {
  margin-top: 17px;
  margin-left: 17px;
}

.cartDebit input {
  background-color: #fff;
  border-radius: 4px;
  width: 96%;
}

.cart-summary1 {
  width: 95%;
  display: flex;
  padding: 10px;
  text-align: center;
  background-color: rgba(242, 242, 242);
  margin-left: 5%;
}

.form-group label {
  color: #b4997c;
  font-size: 0.875rem;
}

.firstLast {
  display: flex;
}

.form-group1 {
  width: 45%;
}

.form-group1 label {
  color: #b4997c;
  font-size: 0.875rem;
}

.form-group-half1 {
  margin-right: 10%;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  height: 49px;
}

.ReactFlagsSelect-module_selectOption__3pcgW {
  height: 30px;
}

.ReactFlagsSelect-module_selectValue__152eS {
  height: 37px;
}

.check-order-info .twoAdd {
  border: 1px solid hsl(0, 0%, 87%);
  border-radius: 5px;
  height: 37px;
  margin: 2% 0;
  padding: 10px;
  width: 100%;
}

.ReactFlagsSelect-module_label__27pw9 {
  font-size: 14px;
}

.basketDiv22 {
  color: #191919;
  font-weight: 600;
  font-size: 40px;
  text-align: left;
}

.rightBotDiv {
  text-align: left;
  margin-top: 8%;
  background-color: #fff;
  color: #000000;
  padding: 0 12px 17px 26px;
}

.cartDebit {
  display: flex;
  margin-bottom: 3%;
}

.cartDebit button {
  padding: 1% 2% 0 4%;
  background-color: transparent;
  color: #b4997c;
  font-weight: 500;
  font-size: 18px;
  width: 96%;
  height: 50px;
  border: 2px solid #b4997c;
  margin-top: 5%;
}

.cartInputDiv {
  overflow: hidden;
  padding: 0 31px 18px 1px;
  width: 96%;
}

.cardNumber label,
.expiryData label,
.cartCode label {
  font-weight: 500;
  font-size: 16px;
  color: #b4997c;
}

.cardNumber input {
  width: 106.7%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 7px;
  height: 47px;
}

.cartData {
  display: flex;
  width: 106%;
}

.cartData input,
.cartCode input {
  width: 100%;
  height: 47px;
}

.rightSum22 {
  padding-top: 2%;
  width: 95%;
  padding-left: 4%;
}

.subtotalTh22 {
  align-content: center;
  letter-spacing: 1px;
  font-size: 23px;
  vertical-align: middle;
  padding: 7px;
  width: 88%;
  font-family: "Bebas Neue", sans-serif !important;
}

.gift-input22 input {
  width: 81%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 42px;
}

.gift-input22 button {
  border: 1px solid #000;
  color: #3d3b3b;
  padding: 2px 9px;
  cursor: pointer;
  border-radius: 9px;
  width: 15%;
  margin-left: 4%;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
}

.privacy-bot {
  padding-left: 1.5%;
}

.privacy-bot a {
  margin-left: 1%;
  color: #b4997c;
}

.check-qty {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  margin-top: -12%;
  margin-left: -5%;
  position: absolute;
  text-align: center;
  font-weight: 500;
  border: 2px solid hsla(0, 0%, 7%, 0.11);
}

.check-item-details {
  width: 70%;
}

.cal-single {
  font-weight: 600;
}

.summary-details22 {
  margin-bottom: 10px;
}

.sumTbody22 {
  text-align: left;
}

.sumTr22 {
  display: flex;
  height: 30px;
}

.subtotalTh22 {
  align-content: center;
  font-size: 16px;
  vertical-align: middle;
  padding: 7px;
  width: 87%;
  font-weight: 500;
}

.subtotalTxt22 {
  text-align: end;
  align-content: center;
  font-family: monospace;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.subtotalTh122 {
  align-content: center;
  font-size: 24px;
  vertical-align: middle;
  padding: 7px;
  width: 81%;
  font-weight: 700;
}

.sumTr22 {
  display: flex;
  height: 30px;
}

.subtotalTxt122 {
  align-content: center;
  font-size: 24px;
  vertical-align: middle;
  padding: 7px;
  font-weight: 700;
  width: 15%;
}

.sumTr2233 {
  display: flex;
  height: 43px;
  border-top: 1px solid hsla(0, 0%, 7%, 0.11);
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding-bottom: 0 !important;
  margin: 2% 0 !important;
}