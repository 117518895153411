 @import url(/src/css/meida/product-xs.scss) only screen and (max-width:375px);
 @import url(/src/css/meida/product-m.scss) only screen and (max-width:590px);
 @import url(/src/css/meida/product-only.scss) only screen and (min-width:590px);
 @import url(/src/css/meida/product-small.scss) only screen and (max-width:768px);
 @import url(/src/css/meida/product-media.scss) only screen and (min-width:768px) and (max-width: 1024px);
 @import url(/src/css/meida/product-big.scss) only screen and (min-width:1024px);

 .index-section {

     .page-width--narrow {
         margin: 0 auto;
         max-width: 1000px;

         .theme-block {
             margin-bottom: 30px;

             h2 {
                 font-weight: 700;
                 letter-spacing: -0.025em;
                 line-height: 1.2;
             }

             :last-child {
                 margin-bottom: 0;
             }
         }

         .enlarge-text p {
             font-weight: 300;
             line-height: 1.6;
         }

     }

     .text-center {
         text-align: center !important;
     }

     .shopify_grid {
         display: grid;
         grid-template-columns: repeat(12, 1fr);

         .text-center {
             text-align: center !important;
         }

         .grid__item {
             padding: 0 20px;
             max-width: 100%;
             width: 100%;

             h3 {
                 font-weight: 700;
                 letter-spacing: -0.025em;
                 line-height: 1.2;
             }
         }

         .text-spacing {
             margin-bottom: 20px;

             p {
                 margin: 0 0 20px;
                 font-size: 17px;
                 font-weight: 400;
                 line-height: 1.6;
             }
         }
     }

     .feature-row__item {
         display: flex;
         align-items: center;
     }

     .shop_grid_next {
         margin: 0 6%;
     }

     .collapsible-trigger--inline {
         font-weight: 700;
         padding: 11px 0 11px 20px;

         .collapsible-trigger__icon {
             left: 0;
             right: auto;
             display: block;
             height: 12px;
             position: absolute;
             right: 0;
             top: 50%;
             transform: translateY(-50%);
             width: 12px;

             .icon {
                 display: block;
                 height: 12px;
                 transition: all .2s ease-in;
                 width: 12px;
             }
         }
     }

     .collapsible-content--all.is-open {
         opacity: 1;
         overflow: unset;
         transition: opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94), height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
         visibility: visible;
         height: auto;

         .rte.collapsible-content__inner--faq {
             padding-bottom: 22px;
         }

         .rte p {
             margin: 0;
             font-size: 17px;
             font-weight: 400;
             line-height: 1.6;

             em {
                 font-style: italic;
             }
         }
     }

     .is-open .collapsible-content__inner {
         transform: translateY(0);
         transition: transform .5s cubic-bezier(.25, .46, .45, .94);
     }

 }

 .reviews-importify-element {
     font-size: initial;
     line-height: initial;
     max-width: 100%;
     visibility: visible;
     margin-top: 5px !important;

     .reviews-importify-reviews {
         padding-bottom: 50px !important;
         margin: 20px 0;
         border: 1px solid #ffd602;
         padding: 10px;
         display: grid;
         grid-template-columns: repeat(12, 1fr);
     }

     .reviews-importify-overview {


         h3 {
             font-size: 22px;
             font-weight: 700;
             letter-spacing: -0.025em;
             line-height: 1.2;
         }
     }

     .reviews-importify-rating-stars {
         display: inline-block;
         position: relative;
         cursor: pointer;
         vertical-align: middle;

         img {
             max-height: 20px;
         }
     }

     .ri_total_reviews {
         font-size: 22px;
         font-weight: 700;
     }

     p {
         margin: 0 0 20px;
         font-size: 17px;
         line-height: 1.6;
     }
 }

 .review-overview-row-col2 {
     .review_bar {
         margin-bottom: 5px;
         min-height: 5px;
         cursor: pointer;

         .bar_star {
             position: relative;
             margin: 0 5px;
             width: auto;
             min-width: 80px;
             float: left;
             height: 15px;
             line-height: .8;
         }

         .bar_line {
             position: relative;
             margin: 0 5px;
             min-width: 80px;
             float: left;
             height: 15px;
             line-height: .8;

             .bar_line_container {
                 background-color: #f1f1f1;
                 text-align: center;
                 color: #fff;
                 width: 100%;

                 .bar_line_filled {
                     width: 0;
                     height: 10px;
                     background-color: #ff9800;
                     margin: initial;
                 }
             }
         }

         .bar_percentage {
             position: relative;
             margin: 0 5px;
             width: auto;
             min-width: 80px;
             float: left;
             height: 15px;
             line-height: .8;
         }

         .clearfloat {
             clear: both;
             width: 100%;
             display: block;
         }
     }
 }

 .review-overview-row-col3 {

     button {
         background-color: #4CAF50;
         border: none;
         color: #fff;
         padding: 5px 10px;
         text-align: center;
         text-decoration: none;
         display: inline-block;
         font-size: 11px;
         margin: 4px 2px;
         cursor: pointer;
         transition-duration: .4s;
         width: auto;
     }
 }

 .reviews_importify_write_review_form_container {
     display: none;
     transition-duration: .4s;
 }

 .reviews_importify_write_review_form_container.is-open {
     display: block;
     visibility: visible;
     transition-duration: .4s;
 }

 .reviews_importify_write_review_form_container {
     fieldset {
         border: 1px solid #dedede;
         padding: 15px;

         legend {
             width: auto;
             border-bottom: none;
             border: 0;
             padding: 0;

             h3 {
                 font-size: 22px;
                 font-weight: 700;
                 letter-spacing: -0.025em;
                 line-height: 1.2;
             }
         }

         .validateTips {
             margin: 0 0 20px;
         }

         .ri-field-row {
             label {
                 display: block;
                 cursor: pointer;
                 font-size: calc((17px)* .75);
                 letter-spacing: .2em;
                 text-transform: uppercase;
                 margin-bottom: 10px;
                 font-weight: 700;
                 line-height: 1.2;
             }

             select {
                 min-width: 400px;
                 max-width: 100%;
                 margin-bottom: 10px;
                 background-position: right 10px center;
                 background-repeat: no-repeat;
                 background-size: 11px;
                 cursor: pointer;
                 display: inline-block;
                 padding-right: 28px;
                 text-indent: .01px;
                 text-overflow: "";
                 vertical-align: middle;
                 border: 1px solid;
                 border-color: #e8e8e1;
                 border-radius: 0;
                 padding: 8px 10px;
             }

             input {
                 min-width: 400px;
                 max-width: 100%;
                 margin-bottom: 10px;
                 background-color: transparent;
                 color: inherit;
                 border: 1px solid;
                 border-color: #e8e8e1;
                 border-radius: 0;
                 padding: 8px 10px;
             }

             textarea {
                 min-width: 400px;
                 max-width: 100%;
                 margin-bottom: 10px;
                 background-color: transparent;
                 color: inherit;
                 min-height: 100px;
                 border: 1px solid;
                 border-color: #e8e8e1;
                 border-radius: 0;
                 padding: 8px 10px;
             }


         }

         .importify-reviews-btn {
             background-color: #4CAF50;
             border: none;
             color: #fff;
             padding: 5px 10px;
             text-align: center;
             text-decoration: none;
             display: inline-block;
             font-size: 11px;
             margin: 4px 2px;
             cursor: pointer;
             transition-duration: .4s;
             width: auto;
             line-height: 1.6;
         }

         .importify-reviews-btn:hover {
             box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
         }
     }
 }

 .ri-reviews-filter-bar-container {
     .reviews-importify-filter-bar {
         background: #EEE;
         padding: 10px;

         .ri-filter-sep {
             margin: 0 6px;
         }

         .ri-filter-select-option {
             width: auto !important;
             height: auto !important;
             line-height: initial;
             min-height: 25px;
             display: inline-block;
             margin-bottom: 0 !important;
             background-image: none;
             padding: 3px;
             font-size: 12px;
             min-width: 150px;
             max-width: 100%;
             background-color: transparent;
             color: inherit;
             background-position: right 10px center;
             background-repeat: no-repeat;
             background-size: 11px;
         }

         .ri-filter-label {
             cursor: pointer;
             letter-spacing: .2em;
             text-transform: uppercase;
             display: inline-block !important;
             margin-bottom: 10px;
             font-weight: 700;
             line-height: 1.2;
         }
     }
 }

 .ri-reviews {
     margin-top: 30px;

     .ri-reviews-row {
         padding-bottom: 10px;
         border-bottom: 1px solid #DDD;
         margin: 10px 0;
         display: grid;
         grid-template-columns: repeat(12, 1fr);

         .ri-review-row-col {
             display: flex;
         }

         .review-user-letter.circle {
             border-radius: 50%;
         }

         .review-user-letter {
             display: inline-block;
             font-size: 1em;
             width: 2.5em;
             height: 2.5em;
             line-height: 2.5em;
             text-align: center;
             background: #e9e9e9;
             vertical-align: middle;
             margin-right: 0;
             color: #666;
             text-transform: uppercase;
         }

         .review-user-name {
             display: flex;
             padding-top: 2%;
             padding-left: 2%;

             svg {
                 width: 12px;
                 height: 12px;
             }
         }
     }
 }

 .review_title_rating_star {
     .rating_star {
         vertical-align: middle;

         svg {
             width: 14px;
             height: 16px;
         }
     }

     .ri_review_title {
         display: inline-block;
         margin-left: 10px;
         font-weight: 700;
         font-size: 14px;
     }
 }

 .ri_review_description {
     margin-top: 5px;
     margin-bottom: 10px;
     font-size: 13px;
 }

 .ri_review_images {
     .ri_review_image {
         display: inline-block;
         margin: 1px;

         img {
             height: 50px;
             padding: 2px;
             border: 1px solid #DDD;
             border-radius: 5px;
             cursor: pointer;
             display: inline-block;
         }

         img:hover {
             border-color: red;
         }
     }
 }

 .rireview-date {
     display: block;
     font-size: 12px;
     margin-top: 5px;

     .fa-calendar {
         vertical-align: middle;
     }

     .verified_text {
         display: inline-block;
         margin-left: 5px;
         padding-left: 5px;
         border-left: 1px solid #ddd;

         span {
             font-weight: 700 !important;
             font-size: 11px !important;
             line-height: 1.465 !important;
             color: #c45500 !important;
             font-family: "Amazon Ember", Arial, sans-serif;
         }
     }
 }

 .ri_reviews_thumbs {
     position: relative;


     .ri_review_thumbs_up {
         display: inline-block;
         position: relative;
         cursor: pointer;

         .fa {
             cursor: pointer;
             color: #737373;
             vertical-align: middle;

             svg {
                 width: 20px;
                 height: 20px;
                 fill: #737373;
             }
         }
     }



     .reviews-importify-tooltiptext {
         visibility: hidden;
         min-width: 120px;
         width: auto;
         background-color: #000;
         color: #fff;
         text-align: center;
         border-radius: 6px;
         padding: 3px 15px;
         position: absolute;
         z-index: 1;
         bottom: 125%;
         left: 50%;
         margin-left: -60px;
         transition: opacity .3s;
         font-size: 12px;
     }


 }

 .ri_reviews_thumbs:hover .reviews-importify-tooltiptext {
     visibility: visible !important;
     opacity: 1;
 }

 .ng-binding-p {
     margin: 0 0 20px;
 }

 .ri_question_title {
     font-size: 14px;
     color: #0066c0;
     margin-bottom: 10px;
     font-weight: 700;

     b,
     strong {
         font-weight: 700;
     }

     .rireview-date {
         display: block;
         font-size: 12px;
         margin-top: 5px;
         font-weight: 400;

         .ri_question_date_author {
             color: #767676;
         }
     }
 }

 .ri_question_answer {
     font-size: 14px;
 }

 .ri_question_date_author {
     color: #767676;
 }