// max-width:768px

.head_top {
    font-size: 14px;
}

.head_bot {
    .head_bot_inner {
        .head_bot_inner_mid {
            img {
                width: 117px;
                height: 29px;
            }
        }
    }
}

//117,29    375
//130 32    414