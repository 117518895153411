.col-mid-1 {
    grid-column: span 1;
}

.col-mid-2 {
    grid-column: span 2;
}

.col-mid-3 {
    grid-column: span 3;
}

.col-mid-4 {
    grid-column: span 4;
}

.col-mid-5 {
    grid-column: span 5;
}

.col-mid-6 {
    grid-column: span 6;
}

.col-mid-7 {
    grid-column: span 7;
}

.col-mid-8 {
    grid-column: span 8;
}

.col-mid-9 {
    grid-column: span 9;
}

.col-mid-10 {
    grid-column: span 10;
}

.col-mid-11 {
    grid-column: span 11;
}

.col-mid-12 {
    grid-column: span 12;
}

.row {
    .row_top {
        .row_top_inner {
            padding: 0 40px;

            .row_top_inner_right {
                .row_top_inner_right_inner {
                    .product-block--header {
                        .product-single__title {
                            font-size: 35px !important;
                        }
                    }

                    .product-block--body {
                        .product-block--price {

                            .product-block--price-pre,
                            .product-block--price-now {
                                font-size: calc((17px) + 4px);
                            }
                        }
                    }
                }


                .collapsibles-wrapper--border-bottom {
                    button {
                        letter-spacing: .0.08em;
                        font-size: calc((17px)*.75);
                    }
                }

            }
        }
    }
}

.row_two {
    .row_two_inner {
        .primary-title {
            font-size: calc((35px)* .85);

            h2 {
                font-size: calc((35px)* .9);
            }
        }
    }
}

.container .row_two .row_two_inner .rebuy-bundle .rebuy-bundle__items .rebuy-product-block {
    margin-left: 25% !important;
}

.rebuy-product-label {
    font-size: 17px !important;
}

.container .row_two .row_two_inner .rebuy-bundle .rebuy-bundle__items .rebuy-product-block .rebuy-product-info {
    font-size: 17px !important;
}

.row_three {
    .primary-title {
        font-size: calc((35px)* .85);

        h2 {
            font-size: calc((35px)* .9);
        }
    }
}

.hero__subtitle {
    font-size: calc((17px)* 1.18);
}

.slideshow-wrapper {
    .hero__text-wrap {
        .page-width {
            h2 {
                font-size: 80px;
            }
        }
    }
}

.hero__text-content {
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
    padding: 20px 0;
}

.index-section {
    .page-width {
        .section-header {
            h2 {
                font-size: calc((35px)* .9);
            }
        }
    }

    .logo-bar {
        .logo-bar__item {
            flex: 0 1 160px;
            margin: 0 26.66667px 40px;
        }
    }

    .page-width--narrow {

        .theme-block {
            h2 {
                font-size: calc((35px)* .9);
                margin: 0 0 20px;
            }
        }

        .enlarge-text p {
            font-size: calc((17px)* 1.4);
        }
    }

    .shopify_grid {
        .grid__item {
            h3 {
                font-size: calc((35px)* .85);
                margin: 0 0 20px;
            }
        }
    }

    .shop_grid_next h3,
    .shop_grid_next p {
        text-align: left !important;
    }

    .shop_grid_next {
        padding: 0 48px;
    }

    .page_right {
        padding-right: 80px !important;
    }
}

.reviews-importify-element {
    .reviews-importify-overview {
        h3 {
            margin: 0 0 20px;
        }
    }
}

.review-overview-row-col2 {
    .review_bar {
        .bar_line {
            width: 48%;

        }

        .bar_percentage {
            font-size: 17px;
        }
    }
}

.review-overview-row-col3 {
    text-align: center;
}

.ri-reviews-filter-bar-container {
    .reviews-importify-filter-bar {
        .ri-filter-label {
            font-size: calc((17px)* .8);
        }
    }
}

.ri_reviews_thumbs {
    text-align: center;
}

.page-content {
    padding-bottom: 60px !important;
    padding-top: 60px !important;
    padding: 0 40px;
}

.privacy_box {
    .section-header {
        h1 {
            font-size: 35px;
            margin: 0 0 20px;
        }
    }

    h2 {
        margin: 0 0 20px;
        font-size: calc((35px)* .85) !important;
    }

    h3 {
        font-size: calc((35px)* .69);
        margin: 0 0 20px;
    }

    h4 {
        font-size: calc((35px)* .59);
        margin: 0 0 20px;
    }

    h1 {
        font-size: calc((35px));
        margin: 0 0 20px;
    }
}