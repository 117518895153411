.col-mid-1 {
    grid-column: span 1;
}

.col-mid-2 {
    grid-column: span 2;
}

.col-mid-3 {
    grid-column: span 3;
}

.col-mid-4 {
    grid-column: span 4;
}

.col-mid-5 {
    grid-column: span 5;
}

.col-mid-6 {
    grid-column: span 6;
}

.col-mid-7 {
    grid-column: span 7;
}

.col-mid-8 {
    grid-column: span 8;
}

.col-mid-9 {
    grid-column: span 9;
}

.col-mid-10 {
    grid-column: span 10;
}

.col-mid-11 {
    grid-column: span 11;
}

.col-mid-12 {
    grid-column: span 12;
}

.collapsible-trigger-btn12 {
    display: none !important;
}

.small--hide {
    display: block;
}


.footer_row_inner .footer_row_one {
    padding-left: 0 !important;
}

.site-footer__bottom {
    padding-top: 40px;
}

.footer_row_inner .footer_row_one .footer__social {
    text-align: left;
}