 @import url(./meida/product-xs.scss) only screen and (max-width:375px);
 @import url(./meida/product-only.scss) only screen and (min-width:590px);
 @import url(./meida/product-small.scss) only screen and (max-width:768px);
 @import url(./meida/product-media.scss) only screen and (min-width:768px) and (max-width: 1024px);
 @import url(./meida/product-big.scss) only screen and (min-width:1024px);


 .privacy_box {
     line-height: 1.6 !important;

     .section-header {
         text-align: center;

         h1 {
             margin-bottom: 0;
             display: block;
             font-weight: 700;
             line-height: 1.2;
             letter-spacing: -0.025em;
         }
     }

     h2 {
         margin-bottom: 1em;
         margin-top: 2.5em;
         font-weight: 700;
         line-height: 1.2;
         letter-spacing: -0.025em;
     }

     h3 {
         margin-bottom: 1em;
         margin-top: 2.5em;
         font-weight: 700;
         line-height: 1.2;
         letter-spacing: -0.025em;
     }

     .custom-content {
         align-items: stretch;
         display: flex;
         flex-wrap: wrap;
         margin-bottom: -30px;
         margin-left: -30px;
         width: auto;
     }

     .align--center {
         align-self: center;
         text-align: center;
     }

     .custom__item {
         flex: 0 0 auto;
         margin-bottom: 30px;
         max-width: 100%;
         padding-left: 30px;
     }

     h4 {
         margin-bottom: 1em;
         margin-top: 2.5em;
         font-weight: 700;
         line-height: 1.2;
         letter-spacing: -0.025em;
     }

     h4:first-child {
         margin-top: 0;
     }

     h1 {
         margin-bottom: 1em;
         margin-top: 2.5em;
         font-weight: 700;
         line-height: 1.2;
         letter-spacing: -0.025em;
     }

     h1:first-child {
         margin-top: 0;
     }
 }

 @media only screen and (min-width: 590px) {
     .index-section {
         margin: 100px 0;
     }

     .page-full,
     .page-width {
         padding: 0 40px;
     }

     .custom-content {
         margin-bottom: -22px;
         margin-left: -22px;
     }

     .custom__item {
         flex: 0 0 auto;
         margin-bottom: 22px;
         padding-left: 22px;
     }
 }