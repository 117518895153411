@import url(/src/css/meida/product-xs.scss) only screen and (max-width:375px);
@import url(/src/css/meida/product-small.scss) only screen and (max-width:768px);
@import url(/src/css/meida/product-media.scss) only screen and (min-width:768px) and (max-width: 1024px);
@import url(/src/css/meida/product-big.scss) only screen and (min-width:1024px);

.col-sm-1 {
    grid-column: span 1;
}

.col-sm-2 {
    grid-column: span 2;
}

.col-sm-3 {
    grid-column: span 3;
}

.col-sm-4 {
    grid-column: span 4;
}

.col-sm-5 {
    grid-column: span 5;
}

.col-sm-6 {
    grid-column: span 6;
}

.col-sm-7 {
    grid-column: span 7;
}

.col-sm-8 {
    grid-column: span 8;
}

.col-sm-9 {
    grid-column: span 9;
}

.col-sm-10 {
    grid-column: span 10;
}

.col-sm-11 {
    grid-column: span 11;
}

.col-sm-12 {
    grid-column: span 12;
}

.row {
    .row_top {
        .row_top_inner {
            padding: 0 40px;

            .row_top_inner_right {
                .row_top_inner_right_inner {
                    .product-block--header {
                        .product-single__title {
                            font-size: calc((35px)* .8) !important;
                        }
                    }

                    .product-block--body {
                        .product-block--price {

                            .product-block--price-pre,
                            .product-block--price-now {
                                font-size: calc((17px) + 2px);
                            }
                        }
                    }
                }

                .also_bought {
                    .rebuy-widget-content {
                        h4 {
                            font-size: calc((35px)* .61* .85);
                        }
                    }
                }

                .product_left_top_label {
                    font-size: 12px !important;
                    letter-spacing: 0.08em !important;
                }

                .product_left_top_price {
                    font-size: 12px !important;
                }

                .product_green {
                    font-size: 12px !important;
                }

            }
        }
    }
}

.container .row .row_top .row_top_inner .row_top_inner_right .row_top_inner_right_inner .product-block--header .reviews-importify-element {
    font-size: 15px !important;
}

.container .row .row_top .row_top_inner .row_top_inner_right .select_subscrib_box .rc_widget__option__plans__dropdown {
    font-size: 12px !important;
    letter-spacing: 0.08em !important;
}

.row_top_inner_right {
    .collapsibles-wrapper--border-bottom {
        button {
            letter-spacing: .0.08em;
            font-size: calc((17px)*.75);
        }
    }
}

.row_two {
    .row_two_inner {
        .primary-title {
            font-size: calc((35px)* .85 *.85);

            h2 {
                font-size: calc((35px)* .9* .85);
            }
        }
    }
}

.container .row_two .row_two_inner .rebuy-bundle .rebuy-bundle__items .rebuy-product-block {
    margin-left: 0 !important;
}

.rebuy-product-label {
    font-size: 13px !important;
}

.container .row_two .row_two_inner .rebuy-bundle .rebuy-bundle__items .rebuy-product-block .rebuy-product-info {
    font-size: 13px !important;
}

.row_three {
    .primary-title {
        font-size: calc((35px)* .85 *.85);

        h2 {
            font-size: calc((35px)* .9* .85);
        }
    }
}

.hero__subtitle {
    font-size: calc((17px)* .85);
}

.slideshow-wrapper {
    .hero__text-wrap {
        .page-width {
            h2 {
                font-size: 40px;
            }
        }
    }
}

.hero__text-content {
    padding: 10px 0;
}

.index-section {
    .page-width {
        .section-header {
            h2 {
                font-size: calc((35px)* .9* .85);
            }
        }
    }

    .logo-bar {
        .logo-bar__item {
            flex: 0 1 110px;
            margin: 0 20px 26.66667px;
        }
    }

    .page-width--narrow {

        .theme-block {
            h2 {
                font-size: calc((35px)* .9* .85);
                margin: 0 0 10px;
            }
        }

        .enlarge-text p {
            font-size: calc((17px)* 1.13);
        }
    }

    .shopify_grid {
        .grid__item {
            h3 {
                font-size: calc((35px)* .85* .85);
                margin: 0 0 10px;
            }
        }
    }

    .shop_grid_next h3,
    .shop_grid_next p {
        text-align: center !important;
    }

}

.reviews-importify-element {
    .reviews-importify-overview {
        h3 {
            margin: 0 0 10px;
        }
    }
}

.height_10 {
    height: 10px;
}

.clearfloat {
    clear: both;
    width: 100%;
    display: block;
}



.review-overview-row-col2 {
    .review_bar {
        .bar_line {
            width: 35%;
        }
    }
}

.review-overview-row-col3 {
    text-align: left;
}

.ri-reviews-filter-bar-container {
    .reviews-importify-filter-bar {
        .ri-filter-label {
            font-size: calc((17px)* .75);
        }
    }
}

.ri_reviews_thumbs {
    text-align: left;
}

.ri-reviews .ri-reviews-row .ri-review-row-col {
    margin-bottom: 15px;
}

.page-content {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    padding: 0 20px;
}

.privacy_box {
    .section-header {
        margin-bottom: 30px;

        h1 {
            font-size: calc((35px)* .85);
            margin: 0 0 10px;
        }
    }

    h2 {
        font-size: calc((35px)* .85* .85) !important;
        margin: 0 0 10px;
    }

    h3 {
        font-size: calc((35px)* .69* .85);
        margin: 0 0 10px;
    }

    .index-section {
        margin: 34px 0;
    }

    .page-full,
    .page-width {
        padding: 0 20px;
    }

    h4 {
        font-size: calc((35px)* .59* .85);
        margin: 0 0 10px;
    }

    h1 {
        font-size: calc((35px)* .85);
        margin: 0 0 10px;
    }
}

.basket-title h1 {
    font-size: 1.6rem !important;
}

.basket-title h1 {
    font-size: 1.6rem !important;
}


.cart-items {
    padding: 0 !important;
}

.egg_title {
    font-size: 12px !important;
}

.rebuy-cart__flyout-item-quantity-widget button {
    width: 16px !important;
    font-size: 8px !important;
}

.rebuy-cart__flyout-item-quantity-widget .rebuy-cart__flyout-item-quantity-widget-label {
    font-size: 10px !important;
    width: 24px !important;
}

.egg_delete_item svg {
    width: 16px !important;
    height: 16px !important;
}

.rebuy-cart__flyout-item-quantity-widget {
    margin-top: 10px !important;
}