.col-xs-1 {
    grid-column: span 1;
}

.col-xs-2 {
    grid-column: span 2;
}

.col-xs-3 {
    grid-column: span 3;
}

.col-xs-4 {
    grid-column: span 4;
}

.col-xs-5 {
    grid-column: span 5;
}

.col-xs-6 {
    grid-column: span 6;
}

.col-xs-7 {
    grid-column: span 7;
}

.col-xs-8 {
    grid-column: span 8;
}

.col-xs-9 {
    grid-column: span 9;
}

.col-xs-10 {
    grid-column: span 10;
}

.col-xs-11 {
    grid-column: span 11;
}

.col-xs-12 {
    grid-column: span 12;
}

.footer_row_inner {
    padding: 0 20px !important;
}

.footer_row_inner .footer_row_one {
    padding-left: 0 !important;
}

.footer_row_inner .footer_row_one .footer__logo {
    margin-bottom: 0 !important;
}

.footer_row_inner .footer_row_one .footer__social {
    text-align: right;
}

.footer__title {
    font-size: calc((35px)* .42);
}

.small--hide {
    display: none;
}

.collapsible-trigger-btn12 {
    padding: 20px 0;
    display: block;
}

.collapsible-content__inner12 {
    visibility: hidden;
    display: none;
}

.footer__collapsible {
    padding: 0 0 20px;
}

.collapsible-content__inner12.is-open {
    opacity: 1;
    overflow: unset;
    transition: opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94),
        height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    visibility: visible;
    height: auto;
}

.footer_row_two:not(:last-child) {
    border-bottom: 1px solid rgb(20 43 111 / 12%)
}

.site-footer__bottom {
    padding-top: 10px;
}

.footer__copyright {
    font-size: 10px !important;
}

.footer_row_inner .footer_row_one {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}