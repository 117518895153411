.col-big-1 {
    grid-column: span 1;
}

.col-big-2 {
    grid-column: span 2;
}

.col-big-3 {
    grid-column: span 3;
}

.col-big-4 {
    grid-column: span 4;
}

.col-big-5 {
    grid-column: span 5;
}

.col-big-6 {
    grid-column: span 6;
}

.col-big-7 {
    grid-column: span 7;
}

.col-big-8 {
    grid-column: span 8;
}

.col-big-9 {
    grid-column: span 9;
}

.col-big-10 {
    grid-column: span 10;
}

.col-big-11 {
    grid-column: span 11;
}

.col-big-12 {
    grid-column: span 12;
}

.collapsible-trigger-btn12 {
    display: none !important;
}

.small--hide {
    display: block;
}

.collapsible-content__inner12 {
    display: block;
}

.site-footer__bottom {
    padding-top: 40px;
}