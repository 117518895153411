.index-section {
    margin: 34px 0;

    .page-width {
        padding: 0 20px;
    }

    .page-width--narrow {

        .theme-block {
            h2 {
                font-size: calc((35px)* .9* .85);
                margin: 0 0 10px;
            }
        }

        .enlarge-text p {
            font-size: calc((17px)* 1.13);
        }
    }
}