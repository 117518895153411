.col-sm-1 {
    grid-column: span 1;
}

.col-sm-2 {
    grid-column: span 2;
}

.col-sm-3 {
    grid-column: span 3;
}

.col-sm-4 {
    grid-column: span 4;
}

.col-sm-5 {
    grid-column: span 5;
}

.col-sm-6 {
    grid-column: span 6;
}

.col-sm-7 {
    grid-column: span 7;
}

.col-sm-8 {
    grid-column: span 8;
}

.col-sm-9 {
    grid-column: span 9;
}

.col-sm-10 {
    grid-column: span 10;
}

.col-sm-11 {
    grid-column: span 11;
}

.col-sm-12 {
    grid-column: span 12;
}

.footer_row_inner {
    padding: 0 20px !important;
}

.footer_row_inner .footer_row_one {
    padding-left: 0 !important;
}

.footer_row_inner .footer_row_one .footer__logo {
    margin-bottom: 0 !important;
}

.small--hide {
    display: none;
}

.collapsible-trigger-btn12 {
    padding: 20px 0;
    display: block;
}

.collapsible-content__inner12 {
    visibility: hidden;
    display: none;
}

.footer__collapsible {
    padding: 0 0 20px;
}

.collapsible-content__inner12.is-open {
    opacity: 1;
    overflow: unset;
    transition: opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94),
        height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    visibility: visible;
    height: auto;
    display: block;
}

.footer_row_two {
    border-bottom: 1px solid rgb(20 43 111 / 12%)
}

.footer_row_two12 {
    border: none;
}

.footer__copyright {
    font-size: 10px;
}

.footer_row_inner .footer_row_one {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.footer_row_inner .footer_row_one .footer__social {
    text-align: right;
}