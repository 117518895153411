.col-xs-1 {
    grid-column: span 1;
}

.col-xs-2 {
    grid-column: span 2;
}

.col-xs-3 {
    grid-column: span 3;
}

.col-xs-4 {
    grid-column: span 4;
}

.col-xs-5 {
    grid-column: span 5;
}

.col-xs-6 {
    grid-column: span 6;
}

.col-xs-7 {
    grid-column: span 7;
}

.col-xs-8 {
    grid-column: span 8;
}

.col-xs-9 {
    grid-column: span 9;
}

.col-xs-10 {
    grid-column: span 10;
}

.col-xs-11 {
    grid-column: span 11;
}

.col-xs-12 {
    grid-column: span 12;
}

.container .row .row_top .row_top_inner .row_top_inner_left .slick-next {
    right: -37px;
}

.product_green {
    font-size: 12px !important;
}

.ri_review_count {
    font-size: 14px !important;
}

.product_left_top_label {
    font-size: 12px !important;
    margin-bottom: 0 !important;
    letter-spacing: 0.08em !important;
}

.also_bought {
    .rebuy-widget-content {
        h4 {
            font-size: calc((35px)* .61* .85);
        }
    }
}

.container .row .row_top .row_top_inner .row_top_inner_right .product_left_top .product_left_top_label {
    font-size: 10.6px !important;
    letter-spacing: 0.08em !important;
}

.container .row .row_top .row_top_inner .row_top_inner_right .row_top_inner_right_inner .product-block--header .reviews-importify-element {
    font-size: 15px !important;
}

.container .row .row_top .row_top_inner .row_top_inner_right .select_subscrib_box .rc_widget__option__plans__dropdown {
    font-size: 12px !important;
    letter-spacing: 0.08em !important;
}

.row_top_inner_right {
    .collapsibles-wrapper--border-bottom {
        button {
            letter-spacing: .0.08em;
            font-size: calc((17px)*.75);
        }
    }
}

.row_two {
    .row_two_inner {
        .primary-title {
            font-size: calc((35px)* .85 *.85);

            h2 {
                font-size: calc((35px)* .9* .85);
            }
        }
    }
}

.container .row_two .row_two_inner .rebuy-bundle .rebuy-bundle__items .rebuy-product-block {
    margin-left: 0 !important;
}

.rebuy-product-label {
    font-size: 13px !important;
}

.container .row_two .row_two_inner .rebuy-bundle .rebuy-bundle__items .rebuy-product-block .rebuy-product-info {
    font-size: 13px !important;
}

.row_three {
    .primary-title {
        font-size: calc((35px)* .85 *.85);

        h2 {
            font-size: calc((35px)* .9* .85);
        }
    }
}

.hero__subtitle {
    font-size: calc((17px)* .85);
}

.slideshow-wrapper {
    .hero__text-wrap {
        .page-width {
            h2 {
                font-size: 40px;
            }
        }
    }
}

.hero__text-content {
    padding: 10px 0;
}



.index-section {
    .page-width {
        .section-header {
            h2 {
                font-size: calc((35px)* .9* .85);
            }
        }
    }

    .logo-bar {
        .logo-bar__item {
            flex: 0 1 110px;
            margin: 0 20px 26.66667px;

            img {
                width: 110px;
                height: 23px;
                object-fit: cover;
            }
        }
    }

    .page-width--narrow {

        .theme-block {
            h2 {
                font-size: calc((35px)* .9* .85);
                margin: 0 0 10px;
            }
        }

        .enlarge-text p {
            font-size: calc((17px)* 1.13);
        }
    }

    .shopify_grid {
        .grid__item {
            h3 {
                font-size: calc((35px)* .85* .85);
                margin: 0 0 10px;
            }
        }
    }

    .shop_grid_next h3,
    .shop_grid_next p {
        text-align: center !important;
    }
}

.reviews-importify-element {
    .reviews-importify-overview {
        h3 {
            margin: 0 0 10px;
        }
    }
}

.review-overview-row-col2 {
    .review_bar {
        .bar_line {
            width: 35%;

        }

        .bar_percentage {
            font-size: 14px;
        }
    }
}

.review-overview-row-col3 {
    text-align: left;
}

.reviews_importify_write_review_form_container {
    fieldset {
        legend {

            h3 {
                margin: 0 0 10px;
            }
        }

    }
}

.reviews_importify_write_review_form_container fieldset .ri-field-row {
    select {
        min-width: 280px !important;
    }

    input {
        min-width: 280px !important;
    }

    textarea {
        min-width: 280px !important;
    }
}

.ri-reviews-filter-bar-container {
    .reviews-importify-filter-bar {
        .ri-filter-label {
            font-size: calc((17px)* .75);
        }
    }
}

.ri_reviews_thumbs {
    text-align: left;
}

.ri-reviews .ri-reviews-row .ri-review-row-col {
    margin-bottom: 15px;
}

.page-content {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    padding: 0 20px;
}

.privacy_box {
    .section-header {
        margin-bottom: 30px;

        h1 {
            font-size: calc((35px)* .85);
            margin: 0 0 10px;
        }
    }

    h2 {
        font-size: calc((35px)* .85* .85) !important;
        margin: 0 0 10px;
    }

    h3 {
        font-size: calc((35px)* .69* .85);
        margin: 0 0 10px;
    }

    .index-section {
        margin: 34px 0;
    }

    .page-full,
    .page-width {
        padding: 0 20px;
    }

    h4 {
        font-size: calc((35px)* .59* .85);
        margin: 0 0 10px;
    }

    h1 {
        font-size: calc((35px)* .85);
        margin: 0 0 10px;
    }
}

.basket-title h1 {
    font-size: 1.6rem !important;
}


.cart-items {
    padding: 0 !important;
}

.egg_title {
    font-size: 10px !important;
}

.rebuy-cart__flyout-item-quantity-widget button {
    width: 16px !important;
    font-size: 8px !important;
}

.rebuy-cart__flyout-item-quantity-widget .rebuy-cart__flyout-item-quantity-widget-label {
    font-size: 10px !important;
    width: 24px !important;
}

.egg_delete_item svg {
    width: 16px !important;
    height: 16px !important;
}

.rebuy-cart__flyout-item-quantity-widget {
    margin-top: 10px !important;
}