.successDiv {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: auto;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 6%;
  margin-bottom: 12%;
}

.checkmark-circle {
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
}

.checkmark-circle .background {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #4caf50;
  position: absolute;
  top: 0;
  left: 0;
  animation: scaleAnimation 0.3s ease-in-out;
}

.modal-content {
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 300px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.checkmark-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: green;
  position: relative;
  margin: 0 auto;
}

.checkmark {
  position: absolute;
  width: 40px;
  height: 80px;
  transform: rotate(45deg);
  top: -10px;
  left: 18px;
}

.checkmark::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 20px;
  background-color: white;
  top: 37px;
  left: 11px;
}

.checkmark::after {
  content: "";
  position: absolute;
  height: 5px;
  width: 45px;
  background-color: white;
  top: 27px;
  left: 11px;
}

.checkmark-circle svg {
  color: #fff;
  font-size: 52px;
  margin-top: 19px;
}

.modal-content p {
  font-size: 25px;
}