@import url(../Component/common/style/common.scss) only screen;
@import url(./meida/product-xs.scss) only screen and (max-width:375px);
@import url(./meida/product-m.scss) only screen and (max-width:590px);
@import url(./meida/product-only.scss) only screen and (min-width:590px);
@import url(./meida/product-small.scss) only screen and (max-width:768px);
@import url(./meida/product-media.scss) only screen and (min-width:768px) and (max-width: 1024px);
@import url(./meida/product-big.scss) only screen and (min-width:1024px);


.container {
    width: 100%;

    .row {
        .row_top {
            padding: 60px 0;

            .row_top_inner {
                margin: 0 auto;
                max-width: 1300px;
                display: grid;
                grid-template-columns: repeat(12, 1fr);

                .row_top_inner_left {
                    .slick-next {
                        right: -37px;
                    }



                    .row_top_inner_left_top {
                        touch-action: pan-y;
                        cursor: grab;

                        img {
                            width: 100%;
                            height: auto;
                        }

                    }

                    .row_top_inner_left_bot {

                        .slick-prev:before {
                            color: #000 !important;
                            margin-right: 20px;
                        }

                        .slick-next:before {
                            color: #000 !important;
                        }

                        .slick-list {
                            width: 86%;
                            margin: 18px auto;
                        }

                        .arrow {
                            font-size: 24px;
                            background: transparent;
                            border: none;
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            z-index: 10;
                        }

                        .prev {
                            left: 10px;

                        }

                        .next {
                            right: 10px;
                        }

                        button {
                            display: inline-block;
                            max-width: 80px;
                            vertical-align: middle;
                            border: 2px solid transparent;
                            width: 100%;
                            height: 100%;
                            padding: 0;

                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        button.active {
                            border: 2px solid #000;
                            box-shadow: inset 0 0 0 2px #142b6f;
                        }
                    }
                }

                .row_top_inner_right {

                    .row_top_inner_right_inner {
                        .product-block--header {
                            margin-bottom: 25px;

                            .nudge_label {
                                padding: 1px 10px;
                                font-weight: 700;
                                background-color: #ffd60236;
                                border-radius: 5px;
                                color: #ec008c;
                                font-size: 16px;
                            }

                            .product-single__title {
                                font-size: 1rem;
                                margin-bottom: 20px;
                                margin-top: 10px !important;
                                word-wrap: break-word;
                                display: block;
                                font-weight: 700;
                                font-family: "Rubik", serif;
                                line-height: 1.2;
                            }

                            .reviews-importify-element {
                                font-size: initial;
                                line-height: initial;
                                margin-top: 5px !important;
                            }

                            .reviews-importify-reviews-ratings {
                                display: flex;
                                align-items: center;
                            }

                        }

                        .product-block--body {
                            .product-block--price {
                                margin-bottom: 25px;

                                .product-block--price-pre {
                                    display: inline-block;
                                    padding-right: 5px;
                                    text-decoration: line-through;
                                }

                                .product-block--price-now {
                                    color: #b12704;
                                    font-family: "Rubik", serif;
                                }

                                .product__policies {
                                    margin-top: 10px;
                                    font-size: .85em;
                                }

                                .rte a:not(.rte__image):not(.btn) {
                                    border-bottom: 2px solid;
                                    border-bottom-color: rgb(20 43 111 / 10%);
                                    position: relative;
                                    -webkit-text-decoration: none;
                                    text-decoration: none;
                                    color: #142b6f;
                                }

                                .product-block {
                                    margin-bottom: 25px;
                                }

                                .sales-points {
                                    list-style: none;
                                    margin: 0;
                                    padding: 0;

                                    .sales-point {
                                        display: block;
                                        margin-bottom: 10px;
                                    }

                                    .sales-point:last-child {
                                        margin-bottom: 0;
                                    }

                                    .icon-and-text {
                                        align-items: center;
                                        display: flex;
                                        flex-wrap: nowrap;

                                        .sales-point .icon {
                                            height: 25px;
                                            margin-right: 10px;
                                            position: relative;
                                            width: 25px;
                                        }

                                        .icon-and-text .icon {
                                            flex: 0 0 auto;
                                        }

                                        .icon {
                                            fill: rgb(20, 43, 111);
                                            display: inline-block;
                                            vertical-align: middle;
                                        }
                                    }
                                }

                                .dw-moneyback-container {
                                    margin-top: 10px;
                                    display: flex;
                                    font-size: smaller;

                                    img {
                                        border: 0 none;
                                        display: block;
                                        height: auto;
                                        max-width: 100%;
                                    }

                                    #dw-signup-newsletter {
                                        margin: auto 10px;
                                        font-size: 14px;
                                        font-weight: 500;
                                        display: inline-block;
                                    }

                                    b,
                                    strong {
                                        font-weight: 700;
                                    }
                                }



                            }
                        }
                    }

                    .product_left_top {
                        border-radius: 4px;
                        border: 1px solid #d2d8e0;
                        margin-top: 5px;
                        position: relative;
                        padding: 12px 16px !important;
                        cursor: pointer;
                        display: block;
                        white-space: nowrap;
                        cursor: pointer;
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .product_left_top_input {
                            display: inline-block;

                            cursor: pointer;
                            border-radius: 50%;
                            border: 1px solid #d2d8e0;
                            width: 19px;
                            height: 19px;
                            vertical-align: middle;
                        }

                        .product_left_top_input:checked {
                            border-radius: 50%;
                            border: 6.5px solid #273376;
                            width: 19px;
                            height: 19px;
                        }

                        .product_left_top_label {
                            color: #273376;
                            cursor: pointer;
                            font-size: calc((17px)*.8);
                            letter-spacing: .2em;
                            text-transform: uppercase;
                            margin-left: 8px;
                            line-height: 2;
                            display: inline-flex;
                            margin-bottom: 0 !important;
                        }

                        .rc-widget .rc-option__text,
                        .rc-widget .rc-option__discount {
                            padding-right: 4px;
                        }

                        .product_left_top_price {
                            color: #B12704;
                            float: right;
                            margin-left: auto;
                            display: inline-flex;
                        }
                    }

                    .select_subscrib_box {

                        .rc-selling-plans__label[data-v-335eb9f1] {
                            cursor: initial;
                            letter-spacing: .2em;
                            text-transform: uppercase;
                            color: inherit;
                        }

                        .visually-hidden {
                            clip: rect(0 0 0 0);
                            border: 0;
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                        }

                        .rc_widget__option__plans__dropdown {
                            padding: 0 30px 0 0;
                            border: none;
                            width: 100%;
                            outline: 0;
                            color: #273376;
                            font-size: 13.6px;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            font-weight: 700;
                            font-family: 'ITC Avant Garde Gothic', sans-serif;
                            background-color: transparent;
                            background-image: url(https://wellgard.co.uk/cdn/shop/t/35/assets/ico-select.svg);
                            background-position: right 10px center;
                            background-repeat: no-repeat;
                            background-size: 11px;
                            cursor: pointer;
                            display: inline-block;
                            text-indent: .01px;
                            text-overflow: "";
                            vertical-align: middle;
                            border-radius: 0;
                            max-width: 100%;
                            line-height: 1.6;
                            margin-left: 8px;

                            option {
                                background-color: #fff;
                                color: #000;
                            }
                        }
                    }

                    .qty_add_flex {
                        display: flex;
                        margin-bottom: 20px;

                        .product__quantity {
                            flex: 0 0 70px;
                            width: 70px;
                            margin-right: 10px;

                            .js-qty__wrapper {
                                background-color: #ffffff;
                                color: #142b6f;
                                max-width: 80px;
                                min-width: 60px;
                                overflow: visible;
                                position: relative;

                                .js-qty__num {
                                    padding: 15px 30px 15px 10px;
                                    border-color: #d2d4d6;
                                    border-radius: 4px;
                                    background: none;
                                    display: block;
                                    margin: 0;
                                    text-align: center;
                                    width: 100%;
                                    border: 1px solid;
                                    max-width: 100%;
                                    font-size: 17px;
                                    font-weight: 400;
                                    line-height: 1.6;
                                }

                                .js-qty__adjust {
                                    fill: #142b6f;
                                    backface-visibility: hidden;
                                    background: none;
                                    border: 0 none;
                                    bottom: 0;
                                    cursor: pointer;
                                    display: block;
                                    line-height: 1;
                                    overflow: hidden;
                                    padding: 0 10px;
                                    position: absolute;
                                    text-align: center;
                                    transition: background-color .1s ease-out;
                                    z-index: 2;
                                }

                                .js-qty__adjust--minus {
                                    height: 50%;
                                    top: auto;
                                    right: 0;
                                    left: auto;
                                }

                                .js-qty__adjust .icon {
                                    fill: inherit;
                                    display: block;
                                    font-size: 8px;
                                    height: 10px;
                                    vertical-align: middle;
                                    width: 10px;
                                }

                                .icon__fallback-text {
                                    clip: rect(0 0 0 0);
                                    border: 0;
                                    height: 1px;
                                    margin: -1px;
                                    overflow: hidden;
                                    padding: 0;
                                    position: absolute;
                                    width: 1px;
                                }

                                .js-qty__adjust--plus {
                                    height: 50%;
                                    bottom: auto;
                                    right: 0;
                                }
                            }


                        }

                        .add-to-cart-btn {
                            border-radius: 4px;
                            font-size: 16px;
                            padding: 11px 20px;
                            width: 100%;
                            background: #ffd602;
                            border: 1px solid transparent;
                            color: #142b6f;
                            cursor: pointer;
                            display: inline-block;
                            line-height: 1.42;
                            margin: 0;
                            min-width: 90px;
                            text-align: center;
                            -webkit-text-decoration: none;
                            text-decoration: none;
                            transition: padding-right .3s, background .3s, opacity 1s;
                            vertical-align: middle;
                            white-space: normal;

                            span {
                                color: #142b6f;
                                font-weight: 700;
                                cursor: pointer;
                                font-size: 18px;
                            }
                        }
                    }

                    .also_bought {
                        margin: 20px 0 20px;
                        background: #fff0;
                        padding: 0;

                        .rebuy-widget-content {
                            h4 {
                                text-transform: none;
                                font-weight: 700;
                                margin-bottom: 10px;
                                color: #142b6f;
                                margin: 0 0 20px;
                                padding: 0;
                            }

                            .rebuy-addon__item {
                                display: flex;
                                flex-flow: row nowrap;
                                justify-content: flex-start;
                                align-items: center;
                                margin-bottom: 25px;

                                .rebuy-addon__item-checkbox {
                                    flex: 0 0 auto;
                                    margin: 0;

                                    .rebuy-checkbox-label {
                                        display: block;
                                        padding: 0;
                                        width: 18px;

                                        .checkbox-input {
                                            position: static;
                                            border-color: #ccc;
                                            margin: 0 10px 0 0;
                                            width: auto;
                                            padding: 0 0 0 15px !important;
                                            transition: all .25s ease-in-out;
                                            display: inline-block;
                                            outline: 0;
                                            border-width: 2px;
                                            border-style: solid;
                                            border-radius: 5px;
                                            background: #fff;
                                            cursor: pointer;
                                            height: 18px;
                                            vertical-align: middle;
                                            color: #fff;
                                        }
                                    }
                                }
                            }

                            .rebuy-addon__item-image {
                                cursor: pointer;
                                padding: 0 10px;
                                width: 80px;
                                flex: 0 0 auto;

                                img {
                                    display: block;
                                    margin: 0;
                                    width: 100%;
                                    max-width: 100%;
                                }
                            }

                            .rebuy-addon__item-info {
                                .rebuy-product-title {
                                    font-size: 17px;
                                    cursor: pointer;
                                    line-height: 1.2;
                                    color: #142b6f;
                                    display: inline-block;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    .product_right_ul {

                        ol,
                        ul {
                            margin: 0 0 20px 40px;
                            padding: 0;
                            text-rendering: optimizeLegibility;

                            li {
                                margin-bottom: 4.25px;
                                line-height: 1.6;
                            }
                        }
                    }

                    p {
                        margin: 0 0 20px;
                    }

                    .collapsibles-wrapper--border-bottom {
                        border-bottom: 1px solid;
                        border-bottom-color: #e8e8e1;

                        .collapsible-trigger-btn--borders:first-of-type {
                            border-top: none;
                        }

                        button {
                            text-transform: uppercase;
                        }

                        .collapsible-trigger__icon--open.rotated svg {
                            transform: rotate(180deg);
                            transition: transform 0.3s ease;
                        }

                        .collapsible-content.is-open {
                            opacity: 1;
                            overflow: unset;
                            transition: opacity 1s cubic-bezier(.25, .46, .45, .94), height .5s cubic-bezier(.25, .46, .45, .94);
                            visibility: visible;
                            height: auto;
                            backface-visibility: hidden;

                            p {
                                margin-bottom: 25px;
                                font-size: 17px;
                                font-weight: 400;
                                line-height: 1.6;
                            }
                        }

                        .collapsible-trigger-btn {
                            display: block;
                            padding: 22.85714px 0;
                            text-align: left;
                            width: 100%;
                            font-weight: 700;
                            line-height: 1.2;
                        }

                        .collapsible-trigger {
                            color: inherit;
                            position: relative;
                        }

                        .collapsible-trigger__icon {
                            display: block;
                            height: 12px;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 12px;

                            .icon {
                                display: block;
                                height: 12px;
                                transition: all .2s ease-in;
                                width: 12px;
                                fill: rgb(20, 43, 111);
                                vertical-align: middle;
                            }
                        }

                    }
                }


            }
        }
    }

    .row_two {
        .row_two_inner {
            max-width: 900px;
            padding: 10px;
            margin: 40px auto;

            .primary-title {
                text-align: center;
                text-transform: none;
                margin-bottom: 40px;
                color: #142b6f;
                margin: 0 0 20px;
                padding: 0;

                h2 {
                    margin: 0 0 20px;
                    font-weight: 700;
                    letter-spacing: -0.025em;
                    line-height: 1.2;
                }
            }

            .rebuy-bundle {
                display: grid;
                grid-template-columns: repeat(12, 1fr);

                .rebuy-bundle__images {
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;

                    .rebuy-bundle__image {
                        width: 150px;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;

                        .rebuy_img_box {
                            display: inline-block;
                            width: calc(100% - 20px);
                            vertical-align: middle;
                            pointer-events: none;
                        }

                        img {
                            border-radius: 20px;
                            width: 100%;
                            max-width: 100%;
                            display: block;
                        }

                        .rebuy-bundle__image-divider {
                            width: 30px;
                            display: inline-block;
                            margin: 0;
                            padding: 0;
                            vertical-align: middle;
                            text-align: center;
                            font-size: 16px;

                            .far {
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 400;
                            }

                            .fa-plus::before {
                                content: "+";
                            }
                        }
                    }
                }

                .rebuy-bundle__actions {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .rebuy-bundle__actions-price {
                        width: auto;
                        margin: 10px 30px;
                        max-width: 100%;
                        font-weight: 400;
                        text-align: center;

                        .rebuy-bundle__actions-price-label {
                            display: inline-block;
                            vertical-align: middle;
                            white-space: nowrap;
                        }

                        b,
                        strong {
                            font-weight: 700;
                        }

                        .rebuy-bundle__actions-price-value {
                            display: inline-block;
                            vertical-align: middle;
                            white-space: nowrap;

                            .rebuy-money.sale {
                                color: #b12704;
                                text-align: right;
                                font-weight: 400;
                                font-size: 17px;
                                line-height: 1.6;
                            }

                            .rebuy-money.compare-at {
                                color: #9a9a9a;
                                text-align: right;
                                text-decoration: line-through;
                                font-weight: 400;
                                font-size: 17px;
                                line-height: 1.6;
                            }
                        }
                    }

                    .rebuy-bundle__actions-buttons {
                        width: auto;
                        margin: 10px 30px;
                        max-width: 100%;

                        button {
                            color: #142b6f;
                            background: #ffd602;
                            border-color: #e8e8e1;
                            border-width: 0;
                            border-radius: 5px;
                            text-transform: none !important;
                            font-family: "ITC Avant Garde Gothic", sans-serif;
                            font-size: 16px;
                            font-weight: 700 !important;
                            letter-spacing: -0.025em;
                            cursor: pointer;
                            display: block;
                            margin: 0;
                            outline: none;
                            box-shadow: none;
                            padding: 10px 15px;
                            width: 100%;
                            height: auto;
                            text-align: center;

                            span {
                                color: #142b6f;
                                font-size: 16px;
                            }
                        }
                    }


                }

                .rebuy-bundle__items {
                    text-align: center;
                    overflow: hidden;
                    margin-top: 20px;
                    width: 100%;

                    .rebuy-bundle__label {
                        cursor: pointer;
                        font-size: 14px;
                        letter-spacing: .2em;
                        text-transform: uppercase;
                        display: block;
                        margin-bottom: 10px;
                        font-weight: 700;
                        line-height: 1.2;
                        text-align: center;
                        display: inline-block;
                    }

                    .rebuy-bundle__accordion-toggle {
                        cursor: pointer;
                        background: none;
                        border: none;
                        transform: rotate(0deg);
                        display: inline-block;
                        color: rgb(20, 43, 111);
                        overflow: visible;
                        font-size: 17px;
                        line-height: 1.6;
                    }

                    .rebuy-product-block {
                        padding-top: 5px !important;
                        text-align: center;
                        padding: 20px 0 0;
                        display: flex;
                        justify-content: stretch;
                        align-items: stretch;

                        .rebuy-product-info {
                            align-items: center;
                            line-height: normal;
                            margin-block: 2px;
                            display: flex;
                            justify-content: center;

                            .rebuy-product-checkbox {
                                margin: 0;

                                .rebuy-checkbox-label {
                                    display: block;
                                    padding: 0;
                                    width: 18px;
                                    position: relative;
                                    margin: 0;
                                    cursor: pointer;
                                    line-height: 18px;
                                    font-size: 14px;
                                    letter-spacing: .2em;
                                    text-transform: uppercase;

                                    .checkbox-input {
                                        position: static;
                                        margin: 4px 10px -5px 0px !important;
                                    }

                                    .rebuy-checkbox:checked {
                                        color: #142b6f;
                                        background: #ffd602 !important;
                                        border-color: #ffd602 !important;
                                    }
                                }
                            }
                        }
                    }

                    .rebuy-product-block.is-selected {
                        opacity: 1;
                    }

                    .rebuy-money.sale {
                        color: #b12704;
                    }

                    .rebuy-money.compare-at {
                        color: #9a9a9a;
                        text-decoration: line-through;
                    }

                    .rebuy-product-price {
                        margin: 0 0 0 5px;
                    }

                    .bundle__items_des {
                        display: none;
                    }

                    .is-open {
                        display: block;
                    }

                    .rebuy-bundle__accordion-toggle svg.rotated {
                        transform: rotate(180deg);
                        transition: transform 0.3s ease;
                    }
                }
            }
        }
    }

    .row_three {
        padding: 30px 0;

        .primary-title {
            color: #142b6f;
            margin: 0 0 20px;
            padding: 0;
            text-align: center;
            font-weight: 700;
            line-height: 1.2;

            h2 {
                margin-top: 0;
                margin-bottom: 29.75px;
            }
        }

        .rebuy_product_grid {
            padding-right: 20px;
            padding-bottom: 20px;
            padding-left: 20px;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            align-items: stretch;

            .rebuy-product-block {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px;
                box-sizing: border-box;

                .rebuy-product-media {
                    cursor: grab;
                    text-align: center;
                    display: block;
                    margin: 0 auto;
                    padding: 0;
                    width: auto;
                    max-width: 100%;
                    line-height: 0;
                    font-size: 0;

                    :hover {
                        border-bottom: 2px solid rgb(20, 43, 111);
                        transform-origin: 0 50%;
                        transition: transform .5s ease;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;
                        object-fit: contain;
                    }
                }

                .rebuy-product-info {
                    .rebuy-product-title.clickable {
                        cursor: pointer;
                        margin: 10px 0 0;
                        color: #142b6f;
                        text-align: center;
                        font-weight: 400;
                        font-size: 17px;

                        :hover {
                            border-bottom: 2px solid rgb(20, 43, 111);
                            transform-origin: 0 50%;
                            transition: transform .5s ease;
                        }
                    }

                    .rebuy-product-price {
                        margin: 5px 0 0;
                        color: #737373;
                        cursor: grab;
                        text-align: center;

                        .rebuy-money {
                            color: #142b6f;
                            text-align: center;
                            font-size: 17px;
                        }

                        .money_red {
                            color: #b12704;
                        }

                        .rebuy-money.compare-at {
                            color: #9a9a9a;
                            text-decoration: line-through;
                        }
                    }


                }

                .rebuy-product-actions {
                    margin-top: 20px;

                    button {
                        color: #142b6f;
                        background: #ffd602;
                        border-color: #e8e8e1;
                        border-width: 0;
                        border-radius: 5px;
                        text-transform: none !important;
                        font-family: "ITC Avant Garde Gothic", sans-serif;
                        font-size: 16px;
                        font-weight: 700 !important;
                        letter-spacing: -0.025em;
                        display: block;
                        margin: 0;
                        outline: none;
                        border-style: solid;
                        box-shadow: none;
                        padding: 10px 15px;
                        width: 100%;
                        height: auto;
                        text-align: center;

                        b,
                        strong {
                            font-weight: 700;
                        }
                    }
                }
            }
        }

    }


    .slideshow-wrapper {
        position: relative;
        width: 100%;
        height: auto;
    }

    .hero__image {
        width: 100%;
        height: auto;
        display: block;
    }

    .hero__text-wrap {
        position: absolute;
        bottom: 0;
        width: 100%;
        color: #fff;
    }

    .page-width {
        display: table;
        height: 100%;
        width: 100%;
        padding: 0 40px;
    }

    .hero__text-content {
        display: table-cell;
        vertical-align: bottom;
        text-align: center;
        padding: 20px 0;
    }

    h2,
    p {
        margin: 0;
    }
}

.index-section {
    .page-width {
        margin: 0 auto;
        max-width: 1300px;

        .section-header {
            text-align: center;
            margin-bottom: 30px;
            font-weight: 400;
            font-size: 17px;
            line-height: 1.6;

            h2 {
                margin: 0;
                font-weight: 700;
            }
        }
    }

    .logo-bar {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: -40px;
        text-align: center;

        .logo-bar__item {
            vertical-align: middle;

            img {
                opacity: 1;
            }
        }
    }
}

.story_box_one {
    margin-top: 64px !important;
}